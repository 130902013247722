*, * :before, :after {
  box-sizing: border-box;
}

:root {
  --real-vh: 100vh;
  --real-vw: 100vw;
  --page-dim: calc(var(--axis) * var(--factor));
  --book-dim: calc(2 * var(--page-dim));
  --half-dim: calc(.5 * var(--page-dim));
  --half-dim-neg: calc(-1 * var(--half-dim));
  --axis: var(--real-vw);
  --factor: .38;
}

.book {
  perspective: 250vw;
}

.book .pages {
  transform: translateX(var(--half-dim-neg));
  transition: opacity .4s .2s, transform 1s;
}

.book.open .pages {
  transform: translateX(0);
}

.book.open.closed .pages {
  transform: translateX(var(--half-dim));
}

.pages {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  width: var(--book-dim);
  height: var(--page-dim);
}

.page {
  -webkit-user-select: none;
  user-select: none;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  transform-origin: 0 0;
  width: var(--page-dim);
  height: var(--page-dim);
  transition: transform 1.5s;
  position: absolute;
  top: 0;
  transform: translate3d(0, 0, 0);
}

.page .shadow {
  background: #ff000003;
  transition: background .7s;
  position: absolute;
  inset: 0;
}

.page .shadow:before {
  content: "";
  z-index: 2;
  background: none;
  transition: background .7s;
  position: absolute;
  inset: 0;
}

.page:nth-child(2n) {
  clear: both;
  pointer-events: none;
  transform-origin: 100% 0;
  border-radius: 4px 0 0 4px;
  left: 0;
  transform: rotateY(180deg);
}

.page:nth-child(2n) .shadow {
  background-image: linear-gradient(to left, #00000012 0%, #0000 10%);
  background-position: 0 0;
}

.page:nth-child(2n) .shadow:before {
  background: #0003;
}

.page:nth-child(2n+1) {
  pointer-events: all;
  border-radius: 0 4px 4px 0;
  right: 0;
  transform: rotateY(0);
}

.page:nth-child(2n+1) .shadow {
  background-image: linear-gradient(to right, #0000001a 0%, #0000 10%);
  background-position: 100% 0;
}

.page:nth-child(2n+1) .shadow:before {
  background: none;
}

.page.flipped:nth-child(2n) {
  pointer-events: all;
  transform: rotateY(0) !important;
}

.page.flipped:nth-child(2n) .shadow:before {
  background: none;
}

.page.flipped:nth-child(2n+1) {
  pointer-events: none;
  transform: rotateY(-180deg) !important;
}

.page.flipped:nth-child(2n+1) .shadow:before {
  background: #0003;
}

.book:not(.flipping) .page:nth-child(2n+1):hover {
  transform: rotateY(-15deg);
}

.book:not(.flipping) .page:nth-child(2n+1):hover .shadow:before {
  background: #00000008;
}

.book:not(.flipping) .page.flipped:nth-child(2n):hover {
  transform: rotateY(15deg);
}

.book:not(.flipping) .page.flipped:nth-child(2n):hover .shadow:before {
  background: #00000008;
}

.book.help .page .ui-help {
  position: absolute;
  inset: 0;
}

.book.help .page:nth-child(2n+1) .ui-help {
  background-image: url("next.0655e91f.svg");
}

.book.help .page:nth-child(2n) .ui-help {
  background-image: url("prev.761faa30.svg");
}

@media only screen and (max-height: 640px) and (orientation: landscape) {
  :root {
    --axis: var(--real-vh);
    --factor: .86;
  }
}

@media only screen and (max-width: 640px) and (orientation: portrait) {
  :root {
    --axis: var(--real-vh);
    --factor: .46;
  }

  .book, #plugContent {
    transform-origin: center;
    transform: rotate(90deg);
  }
}

.rotate-center {
  animation: 3s ease-in-out infinite forwards rotate-center;
}

@keyframes rotate-center {
  0% {
    transform: rotate(0);
  }

  20% {
    transform: rotate(-90deg);
  }

  100% {
    transform: rotate(-90deg);
  }
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.inset-0 {
  inset: 0;
}

.top-0 {
  top: 0;
}

.top-10 {
  top: 2.5rem;
}

.right-\[calc\(50\%-4rem\)\] {
  right: calc(50% - 4rem);
}

.left-0 {
  left: 0;
}

.bottom-0 {
  bottom: 0;
}

.bottom-20 {
  bottom: 5rem;
}

.right-0 {
  right: 0;
}

.right-\[10px\] {
  right: 10px;
}

.isolate {
  isolation: isolate;
}

.z-50 {
  z-index: 50;
}

.-z-10 {
  z-index: -10;
}

.z-40 {
  z-index: 40;
}

.z-10 {
  z-index: 10;
}

.-m-1\.5 {
  margin: -.375rem;
}

.-m-1 {
  margin: -.25rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mr-6 {
  margin-right: 1.5rem;
}

.ml-4 {
  margin-left: 1rem;
}

.block {
  display: block;
}

.flex {
  display: flex;
}

.hidden {
  display: none;
}

.aspect-square {
  aspect-ratio: 1 / 1;
}

.h-8 {
  height: 2rem;
}

.h-full {
  height: 100%;
}

.h-screen {
  height: 100vh;
}

.h-6 {
  height: 1.5rem;
}

.h-5 {
  height: 1.25rem;
}

.min-h-screen {
  min-height: 100vh;
}

.min-h-full {
  min-height: 100%;
}

.w-full {
  width: 100%;
}

.w-auto {
  width: auto;
}

.w-\[69\.25rem\] {
  width: 69.25rem;
}

.w-\[25vw\] {
  width: 25vw;
}

.w-6 {
  width: 1.5rem;
}

.w-32 {
  width: 8rem;
}

.w-5 {
  width: 1.25rem;
}

.w-\[10px\] {
  width: 10px;
}

.w-\[50vw\] {
  width: 50vw;
}

.min-w-full {
  min-width: 100%;
}

.max-w-7xl {
  max-width: 80rem;
}

.max-w-none {
  max-width: none;
}

.max-w-3xl {
  max-width: 48rem;
}

.max-w-\[450px\] {
  max-width: 450px;
}

.flex-1 {
  flex: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-grow {
  flex-grow: 1;
}

.transform-gpu {
  transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.cursor-pointer {
  cursor: pointer;
}

.flex-col {
  flex-direction: column;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-8 {
  gap: 2rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-x-12 {
  column-gap: 3rem;
}

.gap-x-6 {
  column-gap: 1.5rem;
}

.space-x-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.5rem * var(--tw-space-x-reverse));
  margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)) );
}

.overflow-hidden {
  overflow: hidden;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-sm {
  border-radius: .125rem;
}

.rounded-2xl {
  border-radius: 1rem;
}

.rounded-b-lg {
  border-bottom-left-radius: .5rem;
  border-bottom-right-radius: .5rem;
}

.border-t {
  border-top-width: 1px;
}

.border-l {
  border-left-width: 1px;
}

.border-solid {
  border-style: solid;
}

.border-mercury-200 {
  --tw-border-opacity: 1;
  border-color: rgb(230 230 230 / var(--tw-border-opacity));
}

.border-mercury-300 {
  --tw-border-opacity: 1;
  border-color: rgb(209 209 209 / var(--tw-border-opacity));
}

.bg-mercury-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(90 90 90 / var(--tw-bg-opacity));
}

.bg-mercury-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(248 248 248 / var(--tw-bg-opacity));
}

.bg-fuel-yellow-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(239 159 42 / var(--tw-bg-opacity));
}

.bg-thunderbird-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(183 26 35 / var(--tw-bg-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-mercury-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(78 78 78 / var(--tw-bg-opacity));
}

.bg-cover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-page {
  --tw-bg-opacity: 1;
  background-color: rgb(240 240 240 / var(--tw-bg-opacity));
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.bg-mercury-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(154 154 154 / var(--tw-bg-opacity));
}

.bg-opacity-50 {
  --tw-bg-opacity: .5;
}

.bg-cover {
  background-size: cover;
}

.object-cover {
  object-fit: cover;
}

.object-center {
  object-position: center;
}

.p-1\.5 {
  padding: .375rem;
}

.p-1 {
  padding: .25rem;
}

.p-2 {
  padding: .5rem;
}

.p-12 {
  padding: 3rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.pt-24 {
  padding-top: 6rem;
}

.pb-24 {
  padding-bottom: 6rem;
}

.pt-8 {
  padding-top: 2rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pr-4 {
  padding-right: 1rem;
}

.pl-4 {
  padding-left: 1rem;
}

.text-center {
  text-align: center;
}

.font-display {
  font-family: Dirty, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

.font-body {
  font-family: Comfortaa, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

.font-sans {
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

.text-6xl {
  font-size: 3.75rem;
  line-height: 1;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.font-bold {
  font-weight: 700;
}

.leading-\[0\.8\] {
  line-height: .8;
}

.leading-8 {
  line-height: 2rem;
}

.leading-5 {
  line-height: 1.25rem;
}

.leading-7 {
  line-height: 1.75rem;
}

.tracking-tight {
  letter-spacing: -.025em;
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-mercury-400 {
  --tw-text-opacity: 1;
  color: rgb(180 180 180 / var(--tw-text-opacity));
}

.text-mercury-200 {
  --tw-text-opacity: 1;
  color: rgb(230 230 230 / var(--tw-text-opacity));
}

.text-thunderbird-700 {
  --tw-text-opacity: 1;
  color: rgb(183 26 35 / var(--tw-text-opacity));
}

.text-mercury-700 {
  --tw-text-opacity: 1;
  color: rgb(106 106 106 / var(--tw-text-opacity));
}

.text-mercury-500 {
  --tw-text-opacity: 1;
  color: rgb(154 154 154 / var(--tw-text-opacity));
}

.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}

.text-fuel-yellow-400 {
  --tw-text-opacity: 1;
  color: rgb(239 159 42 / var(--tw-text-opacity));
}

.text-mercury-300 {
  --tw-text-opacity: 1;
  color: rgb(209 209 209 / var(--tw-text-opacity));
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-sm {
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-2xl {
  --tw-shadow: 0 25px 50px -12px #00000040;
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.blur-3xl {
  --tw-blur: blur(64px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition-opacity {
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-150 {
  transition-duration: .15s;
}

@font-face {
  font-family: Nimbus;
  src: url("Nimbus-Roman.37ab0e53.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Comfortaa;
  src: url("Comfortaa-Medium.b7765a8d.woff") format("woff");
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: Dirty;
  src: url("Dirty-SevenTwo.2b883602.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

.hover\:bg-thunderbird-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(222 36 47 / var(--tw-bg-opacity));
}

.hover\:bg-success:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(48 106 0 / var(--tw-bg-opacity));
}

.hover\:bg-mercury-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(180 180 180 / var(--tw-bg-opacity));
}

.hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.hover\:underline:hover {
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}

.hover\:opacity-80:hover {
  opacity: .8;
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

@media (min-width: 640px) {
  .sm\:right-\[calc\(50\%-18rem\)\] {
    right: calc(50% - 18rem);
  }

  .sm\:right-4 {
    right: 1rem;
  }

  .sm\:mt-10 {
    margin-top: 2.5rem;
  }

  .sm\:w-1\/2 {
    width: 50%;
  }

  .sm\:justify-center {
    justify-content: center;
  }

  .sm\:pt-20 {
    padding-top: 5rem;
  }

  .sm\:pb-0 {
    padding-bottom: 0;
  }

  .sm\:pt-10 {
    padding-top: 2.5rem;
  }

  .sm\:pb-32 {
    padding-bottom: 8rem;
  }

  .sm\:text-8xl {
    font-size: 6rem;
    line-height: 1;
  }

  .sm\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .sm\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .sm\:leading-\[0\.8\] {
    line-height: .8;
  }
}

@media (min-width: 768px) {
  .md\:order-2 {
    order: 2;
  }

  .md\:order-1 {
    order: 1;
  }

  .md\:mt-0 {
    margin-top: 0;
  }

  .md\:flex {
    display: flex;
  }

  .md\:items-center {
    align-items: center;
  }

  .md\:justify-between {
    justify-content: space-between;
  }

  .md\:p-6 {
    padding: 1.5rem;
  }

  .md\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .md\:py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
}

@media (min-width: 1024px) {
  .lg\:right-48 {
    right: 12rem;
  }

  .lg\:top-\[calc\(50\%-30rem\)\] {
    top: calc(50% - 30rem);
  }

  .lg\:mr-8 {
    margin-right: 2rem;
  }

  .lg\:max-w-2xl {
    max-width: 42rem;
  }

  .lg\:flex-row {
    flex-direction: row;
  }

  .lg\:justify-start {
    justify-content: flex-start;
  }

  .lg\:justify-end {
    justify-content: flex-end;
  }

  .lg\:justify-between {
    justify-content: space-between;
  }

  .lg\:gap-14 {
    gap: 3.5rem;
  }

  .lg\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .lg\:py-40 {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }

  .lg\:text-start {
    text-align: start;
  }

  .lg\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}

@media (min-width: 1280px) {
  .xl\:right-\[calc\(50\%-24rem\)\] {
    right: calc(50% - 24rem);
  }

  .xl\:w-1\/4 {
    width: 25%;
  }
}

@media only screen and (max-width: 640px) and (orientation: portrait) {
  .port\:fixed {
    position: fixed;
  }

  .port\:top-0 {
    top: 0;
  }

  .port\:left-0 {
    left: 0;
  }

  .port\:right-0 {
    right: 0;
  }

  .port\:left-\[unset\] {
    left: unset;
  }

  .port\:bottom-0 {
    bottom: 0;
  }

  .port\:top-\[unset\] {
    top: unset;
  }

  .port\:block {
    display: block;
  }

  .port\:hidden {
    display: none;
  }

  .port\:h-full {
    height: 100%;
  }

  .port\:w-full {
    width: 100%;
  }

  .port\:rotate-90 {
    --tw-rotate: 90deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .port\:flex-col {
    flex-direction: column;
  }

  .port\:pr-6 {
    padding-right: 1.5rem;
  }
}

@media only screen and (max-height: 640px) and (orientation: landscape) {
  .land\:fixed {
    position: fixed;
  }

  .land\:top-0 {
    top: 0;
  }

  .land\:left-0 {
    left: 0;
  }

  .land\:hidden {
    display: none;
  }

  .land\:h-full {
    height: 100%;
  }

  .land\:w-full {
    width: 100%;
  }

  .land\:pt-6 {
    padding-top: 1.5rem;
  }
}

/*# sourceMappingURL=index.7d034e3c.css.map */
