@import "book.scss";

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Nimbus";
  src: url("../assets/fonts/Nimbus-Roman.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Comfortaa";
  src: url("../assets/fonts/Comfortaa-Medium.woff") format("woff");
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "Dirty";
  src: url("../assets/fonts/Dirty-SevenTwo.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
