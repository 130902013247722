*,
* :before,
*:after {
  box-sizing: border-box;
}
:root {
  --real-vh: 100vh;
  --real-vw: 100vw;
  --page-dim: calc(var(--axis) * var(--factor));
  --book-dim: calc(2 * var(--page-dim));
  --half-dim: calc(0.5 * var(--page-dim));
  --half-dim-neg: calc(-1 * var(--half-dim));
  //specific desktop vars
  --axis: var(--real-vw);
  --factor: 0.38;
}
.book {
  perspective: 250vw;
  .pages {
    transition: opacity 0.4s 0.2s, transform 1s;
    transform: translateX(var(--half-dim-neg));
  }
  &.open {
    .pages {
      transform: translateX(0);
    }
    &.closed {
      .pages {
        transform: translateX(var(--half-dim));
      }
    }
  }
}
//
//
//
.pages {
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  width: var(--book-dim);
  height: var(--page-dim);
}
.page {
  position: absolute;
  top: 0;
  user-select: none;
  -webkit-user-select: none;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  transform-origin: top left;
  transition: transform 1.5s;
  transform: translate3d(0, 0, 0);
  width: var(--page-dim);
  height: var(--page-dim);
  .shadow {
    background: rgba(255, 0, 0, 0.01);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transition: background 0.7s;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(0, 0, 0, 0);
      transition: background 0.7s;
      z-index: 2;
    }
  }
  &:nth-child(even) {
    clear: both;
    border-radius: 4px 0 0 4px;
    left: 0;
    pointer-events: none;
    transform: rotateY(180deg);
    transform-origin: 100% 0;
    .shadow {
      background-position: left top;
      background-image: linear-gradient(to left, rgba(0, 0, 0, 0.07) 0%, rgba(0, 0, 0, 0) 10%);
      &::before {
        background: rgba(0, 0, 0, 0.2);
      }
    }
  }
  &:nth-child(odd) {
    border-radius: 0 4px 4px 0;
    right: 0;
    pointer-events: all;
    transform: rotateY(0deg);
    .shadow {
      background-position: right top;
      background-image: linear-gradient(to right, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0) 10%);
      &::before {
        background: rgba(0, 0, 0, 0);
      }
    }
  }
  &.flipped {
    &:nth-child(even) {
      pointer-events: all;
      transform: rotateY(0deg) !important;
      .shadow::before {
        background: rgba(0, 0, 0, 0);
      }
    }
    &:nth-child(odd) {
      pointer-events: none;
      transform: rotateY(-180deg) !important;
      .shadow::before {
        background: rgba(0, 0, 0, 0.2);
      }
    }
  }
}
//
// prevents page-animation on hover while page is flipping or book is closed
//
.book:not(.flipping) {
  .page {
    &:nth-child(odd) {
      &:hover {
        transform: rotateY(-15deg);
        .shadow::before {
          background: rgba(0, 0, 0, 0.03);
        }
      }
    }
    &.flipped {
      &:nth-child(even) {
        &:hover {
          transform: rotateY(15deg);
          .shadow::before {
            background: rgba(0, 0, 0, 0.03);
          }
        }
      }
    }
  }
}
//
// ui-help for DAUs
//
.book.help {
  .page {
    .ui-help {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
    &:nth-child(odd) {
      .ui-help {
        background-image: url("../assets/ui/next.svg");
      }
    }
    &:nth-child(even) {
      .ui-help {
        background-image: url("../assets/ui/prev.svg");
      }
    }
  }
}
//
//
//
/* Smartphone (landscape) ----------- */
@media only screen and (max-height: 640px) and (orientation: landscape) {
  :root {
    //specific landscape vars
    --axis: var(--real-vh);
    --factor: 0.86;
  }
}
/* Smartphone (portrait) ----------- */
@media only screen and (max-width: 640px) and (orientation: portrait) {
  :root {
    //specific portrait vars
    --axis: var(--real-vh);
    --factor: 0.46;
  }
  .book {
    transform: rotate(90deg);
    transform-origin: center;
  }
  #plugContent {
    transform: rotate(90deg);
    transform-origin: center;
  }
}
.rotate-center {
  -webkit-animation: rotate-center 3s ease-in-out forwards infinite;
  animation: rotate-center 3s ease-in-out forwards infinite;
}
@-webkit-keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  20% {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }
  100% {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }
}
@keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  20% {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }
  100% {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }
}
